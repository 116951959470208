<template>
  <div class="refer-link">
    <div class="refer-card">
      <sign title="分享好友" />
      <div class="link">
        <span class="link-text">{{ linkUrl }}</span>
        <span
          v-clipboard:copy="linkUrl"
          v-clipboard:success="firstCopySuccess"
          v-clipboard:error="firstCopyError"
          class="iconfont iconfuzhi"
        ></span>
      </div>
      <div class="qrcode">
        <h4 class="qrcode-title">我的二维码</h4>
        <div ref="qrcodeRef" class="qrcode-img"></div>
      </div>
      <div class="your-address">
        <h4 class="your-address-title">我的地址</h4>
        <p class="your-address-content">
          {{ this.getAddress }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { qrcanvas } from "qrcanvas";
import Sign from "../../components/Sign.vue";
import { mapState } from "vuex";

export default {
  name: "ReferLink",
  components: { Sign },

  computed: {
    ...mapState({ userInfo: (state) => state.common.userInfo }),
    linkUrl() {
      return `${window.location.protocol}//${window.location.host}/login?code=${this.userInfo.code}`;
    },
  },
  methods: {
    // 初始化二维码
    initQrcode() {
      const canvas = qrcanvas({
        data: this.linkUrl,
        size: 64,
      });
      this.$refs.qrcodeRef.innerHTML = "";
      this.$refs.qrcodeRef.appendChild(canvas);
    },
    // 复制成功
    firstCopySuccess() {
      this.$toast("复制成功");
    },
    firstCopyError() {
      this.$toast("该设备不支持复制");
    },
  },
  mounted() {
    this.initQrcode();
  },
};
</script>

<style scoped lang="less">
.refer-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 15px;

  .refer-card {
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 345px;
    height: 313px;
    background: #161f2c;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.09);
    opacity: 1;
    border-radius: 10px;

    .link {
      margin-top: 14px;
      width: 315px;
      height: 50px;
      background: #0f151f;
      opacity: 1;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      .link-text {
        width: 180px;
        font-size: 14px;
        height: 14px;
        font-family: "PingFang SC";
        font-weight: 500;
        color: #27cc90;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }

      .iconfuzhi {
        font-size: 12px;
        color: #27cc90;
      }
    }

    .qrcode {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      h4 {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #e9ecf0;
        margin-top: 15px;
        margin-bottom: 13px;
      }

      .qrcode-img {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    .your-address {
      margin-top: 18px;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-family: PingFang SC;
      color: #e9ecf0;
      font-weight: 500;

      .your-address-title {
        font-size: 14px;
      }

      .your-address-content {
        margin-top: 5px;
        font-size: 12px;
      }
    }
  }
}
</style>
